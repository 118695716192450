.homepage-header {
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.homepage-header__links {
  a {
    font-size: 18px;
    color: inherit;
    text-decoration: none;
  }
  @media screen and (min-width: 1250px) {
    transform: translateX(7vw);
  }
}
.homepage__logo {
  width: 170px;
  align-self: flex-start;
  img {
    display: block;
    width: 100%;
  }
}
