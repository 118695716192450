@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-Regular.woff2') format('woff2'),
    url('../fonts/Boston-Regular.woff') format('woff');
  font-weight: normal;

  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-Black.woff2') format('woff2'),
    url('../fonts/Boston-Black.woff') format('woff');
  font-weight: 900;

  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-Light.woff2') format('woff2'),
    url('../fonts/Boston-Light.woff') format('woff');
  font-weight: 300;

  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-Heavy.woff2') format('woff2'),
    url('../fonts/Boston-Heavy.woff') format('woff');
  font-weight: 900;

  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-LightIt.woff2') format('woff2'),
    url('../fonts/Boston-LightIt.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-SemiBold.woff2') format('woff2'),
    url('../fonts/Boston-SemiBold.woff') format('woff');
  font-weight: 600;

  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-Bold.woff2') format('woff2'),
    url('../fonts/Boston-Bold.woff') format('woff');
  font-weight: bold;

  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-ThinIt.woff2') format('woff2'),
    url('../fonts/Boston-ThinIt.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-Thin.woff2') format('woff2'),
    url('../fonts/Boston-Thin.woff') format('woff');
  font-weight: 100;

  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-SemiBoldIt.woff2') format('woff2'),
    url('../fonts/Boston-SemiBoldIt.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-ExtraLightIt.woff2') format('woff2'),
    url('../fonts/Boston-ExtraLightIt.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-BoldIt.woff2') format('woff2'),
    url('../fonts/Boston-BoldIt.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-BlackIt.woff2') format('woff2'),
    url('../fonts/Boston-BlackIt.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-HeavyIt.woff2') format('woff2'),
    url('../fonts/Boston-HeavyIt.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-ExtraLight.woff2') format('woff2'),
    url('../fonts/Boston-ExtraLight.woff') format('woff');
  font-weight: 200;

  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../fonts/Boston-RegularIt.woff2') format('woff2'),
    url('../fonts/Boston-RegularIt.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Apple Color Emoji';
  src: url('../fonts/AppleColorEmoji.woff2') format('woff2'),
    url('../fonts/AppleColorEmoji.woff') format('woff');
  font-display: swap;
}
