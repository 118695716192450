@import './elements';
@import './fonts';
@import './phone-input';
#root,
.page-wrap {
  height: 100%;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
.main-content {
  min-height: calc(100% - 58px);
}

body {
  font-size: 24px;
  font-family: 'Boston', sans-serif !important;
  color: #434e5f !important;
  margin: 0;
  @media only screen and (max-width: 580px) {
    font-size: 16px;
  }
  p {
    margin-bottom: 0;
  }
  .mt-5 {
    margin-top: 40px;
  }
  .mt-4 {
    margin-top: 32px;
  }
  .mt-3 {
    margin-top: 24px;
  }
  .mt-2 {
    margin-top: 16px;
  }
  .mt-1 {
    margin-top: 8px;
  }
  .mb-1 {
    margin-bottom: 4px;
  }
  .ml-4 {
    margin-left: 32px;
  }
  .ml-3 {
    margin-left: 24px;
  }
  .ml-2 {
    margin-left: 16px;
  }
  .ml-1 {
    margin-left: 8px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #3b4554;
  }
  .h-1,
  .h-2,
  .h-3,
  .h-4,
  .h-5 {
    margin-bottom: 0;
    font-family: 'Boston', sans-serif;

    line-height: normal;
    letter-spacing: normal;
    color: #253149;
    font-weight: 900;
  }
  .h-5 {
    font-size: 14px;
  }
  .h-4 {
    font-size: 16px;
  }
  .h-3 {
    font-size: 20px;
  }
  .h-2 {
    font-size: 24px;
  }
  .h-1 {
    font-size: 32px;
  }
  .icon {
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    fill: currentColor;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .text-bold {
    font-weight: 700;
  }
  .text-heavy {
    font-weight: 900;
  }
  .text-crossed {
    text-decoration: line-through;
  }
  .text-uppercase {
    text-transform: uppercase;
  }
  .text-underline {
    text-decoration: underline;
  }
  .first-uppercase::first-letter {
    font-size: 1.4em;
  }
  .flex-center {
    display: flex;
    align-items: center;
  }
  .c_light-teal {
    color: #96e3dd;
  }
  .c_teal {
    color: #6ed2ca;
  }
  .c_grey {
    color: #b0bdc2;
  }
  .c_darkgrey {
    color: #434e5f;
  }
  .c_white {
    color: #fff;
  }
  .c_red {
    color: #ff8c80;
  }
  .fz-16 {
    font-size: 16px !important;
  }
  .fz-12 {
    font-size: 12px !important;
  }
  .flip-hr {
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .box-shadow {
    padding: 20px;
    box-shadow: 0 2px 6px 0 rgba(59, 69, 84, 0.15);
    background-color: #fff;
    border-radius: 10px;
  }

  // list
  .list {
    padding-left: 0;
    list-style-type: none;
  }
  .list__item {
    & + .list__item {
      margin-top: 12px;
    }
  }
  .list__item-row {
    display: flex;
    align-items: center;
  }
  .list__icon {
    display: inline-flex;
    align-items: center;
    width: 27px;
    margin-right: 16px;
    .icon {
      font-size: 27px;
    }
  }
  .list__title {
    font-size: 24px;
    font-weight: bold;
    color: inherit;
    line-height: normal;
    letter-spacing: -0.38px;
  }
  .list__descr {
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: -0.25px;
  }
  .ant-layout-content {
    background: transparent;
  }
  // antd collapse
  .ant-collapse {
    border: none;
  }
  .ant-collapse > .ant-collapse-item {
    border: none;
    box-shadow: 0 10px 25px 0 rgba(45, 7, 73, 0.1);
    border-radius: 10px !important;
    overflow: hidden;
    background-color: #fff;
    padding: 0 16px;
    @media only screen and (max-width: 580px) {
      padding: 0;
    }
    & + .ant-collapse-item {
      margin-top: 24px;
    }
    & > .ant-collapse-header {
      position: relative;
      padding-left: 16px;
      color: #3b4554;
      font-size: 18px;
      font-weight: 900;
      text-transform: uppercase;
      background-color: transparent;
    }
  }
  .ant-collapse-content {
    color: #3b4554;
    background-color: transparent;
    border-top: none;
    font-size: 16px;
    .ant-collapse-content-box {
      padding-top: 0;
    }
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    color: inherit;
    line-height: initial;
    vertical-align: auto;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 16px;
    left: auto;
    display: inline-flex;
    padding: 0;
    align-items: center;
    font-size: 12px;
  }
  // ANTD buttons
  a.btn,
  .btn,
  .ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-shadow: none;
    text-decoration: none;
    box-shadow: none;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal;
    border-radius: 4px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &.ant-btn-lg {
      height: 48px;
      padding: 6.4px 15px;
    }
    &.w-100 {
      width: 100%;
    }
    &[disabled] {
      background-color: #d0e1f9 !important;
      box-shadow: 0 2px 6px 0 rgba(61, 93, 146, 0.25) !important;
      color: #fff !important;
    }
    .icon {
      fill: currentColor;
    }
    .icon + span,
    span + .icon {
      margin-left: 8px;
    }
    &.small {
      width: 116px;
    }
    &.wide {
      width: 320px;
    }
    &.primary {
      background-color: #96e3dd;
      font-size: 15px;
      font-weight: 900;
      line-height: 1;
      letter-spacing: 1px;
      color: #3b4554;
      border: none;
      text-transform: uppercase;
      border-radius: 24px;
      &:not([disabled]):hover {
        background-color: #96e3dd;
      }
      &[disabled] {
        background-color: #e4e6e7 !important;
        box-shadow: none !important;
        color: #3b4554 !important;
        opacity: 0.5;
      }
      &.dark {
        background: rgba(59, 69, 84, 0.7);
        color: #fff;
        text-transform: capitalize;
        &:not([disabled]):hover {
          background-color: #ff8c80;
        }
      }
      &.black {
        background: #000000;
        color: #fff;
      }
    }
    &.secondary {
      font-size: 15px;
      font-weight: 900;
      line-height: 1;
      letter-spacing: 1px;
      color: #3b4554;
      border: none;
      text-transform: uppercase;
      border-radius: 10px;
      box-shadow: 0 2px 6px 0 rgba(61, 93, 146, 0.25);
      background-color: #ffffff;
      border: none;
      &:not([disabled]):hover {
        box-shadow: 0 2px 6px 0 hsl(175deg, 58%, 54%);
      }
    }
    &.transparent {
      height: auto;
      padding: 0;
      box-shadow: none;
      background-color: transparent;
      color: #808a8e;
      border: none;
    }
    &.ant-btn-link {
      padding: 0;
      color: #2a78e4;
    }
  }
  // end ANTD buttons

  // Form elements
  .form-element-wrap {
    position: relative;
    display: inline-flex;
    margin-top: 22vh;
    max-width: 360px;
    & + .form-element-wrap {
      margin-top: 16px;
    }
  }
  // Input text
  .input-text.ant-input {
    font-size: 44px;
    font-weight: 900;
    line-height: 1.14;
    color: #3b4554;
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #d2d5d8;
    text-align: center;
    padding: 16px;
    &:focus,
    .ant-input-focus {
      box-shadow: none;
      border-color: #3b4554;
    }
    &.short-width {
      max-width: 140px;
    }
  }
  .input-text-label {
    margin: auto;
    display: inline-flex;
    align-items: center;
    font-size: 24px;
    font-weight: 900;
    line-height: 1.25;
  }
  .modal-custom {
    text-align: center;
    color: #3b4554;

    .ant-modal-content {
      border-radius: 15px;
      box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.2);
      background-color: #ffffff;
    }
    .ant-modal-body {
      padding: 30px 24px;
    }
    .modal__caption {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.25;
      color: inherit;
    }
    .modal__footer {
      margin-top: 20px;
      .ant-btn {
        width: 100%;
      }
    }
    .modal__pic {
      margin: -30px -24px -20px;
      padding-top: 46px;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 189, 181, 0.2),
        rgba(255, 189, 181, 0)
      );
      img {
        display: inline-block;
        max-height: 210px;
      }
    }
  }
  .ant-modal-mask,
  .ant-image-preview-mask {
    backdrop-filter: blur(3px);
    background-color: rgba(59, 69, 84, 0.8);
  }

  // Animation
  .animation-wrap {
    position: relative;
    width: 100%;
    padding: 5vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .slide-exiting {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  // Block
  .block__header {
    text-align: center;
  }
  .block__title {
    font-size: 20px;
    font-weight: 900;

    line-height: 1.7;
    letter-spacing: normal;
    color: #3b4554;
    text-transform: uppercase;
  }
  .block__subtitle {
    margin-top: 6px;
    font-size: 16px;
    font-weight: 600;

    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  // Form
  .form {
    .ant-form-item-control-input {
      input,
      .ant-input-affix-wrapper {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid rgba(59, 69, 84, 0.2);
        padding-left: 0;
        padding-right: 0;
        font-size: 16px;
        font-weight: bold;
        color: #3b4554;
        &::placeholder {
          font-size: 16px;
          font-weight: 600;
          color: #a7b5ba;
        }
      }
      .ant-input-affix-wrapper input {
        border: none !important;
      }
    }
    .ant-form-item-explain {
      text-align: left;
      font-size: 12px;
    }
    .ant-input:focus,
    .ant-input-focused,
    .ant-input-affix-wrapper-focused {
      border-color: rgb(59, 69, 84);
      border-right-width: 0px !important;
      box-shadow: none;
    }
    .form__footer {
      margin-top: 48px;
    }
    // input password feedback icon
    .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
      padding-right: 0;
    }
    .ant-form-item-children-icon {
      display: none !important;
    }
    .icon-lock {
      fill: #b2b2b2;
    }
    .ant-form-item-has-error .icon-lock {
      fill: #ff4d4f;
    }
    .ant-form-item-has-success .icon-lock {
      fill: rgb(11, 233, 48);
    }
    .ant-input-group-addon {
      padding-left: 0;
      background-color: transparent;
      border: 1px solid transparent;
      border-bottom-color: rgba(59, 69, 84, 0.2);
      color: #3b4554;
      font-weight: 700;
      .ant-select-focused .ant-select-selector {
        color: #3b4554;
      }
      .ant-select-selection-item {
        font-size: 16px;
      }
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .ant-select.ant-select-open {
    .ant-select-arrow {
      transform: scaleY(-1);
    }
  }
  .ant-select-arrow {
    vertical-align: -0.125em;
    top: 36%;
    width: 24px;
    height: 24px;
    font-size: 24px;
  }
}
// Duo select
.duo-select {
  display: flex !important;
  max-width: 120px;
  margin: 16px auto 0 !important;
  background-color: rgba(127, 190, 235, 0.2);
  font-size: 13px;
  font-weight: bold;
  line-height: 1.23;
  color: #7fbeeb;
  text-transform: uppercase;
  border-radius: 17px;
  overflow: hidden;
  &.duo-select--wide {
    max-width: 200px;
    text-transform: initial;
    .ant-radio-wrapper {
      font-size: 13px;
      span {
        width: 100px;
      }
    }
  }
  .ant-radio {
    display: none !important;
  }
  .ant-radio-wrapper {
    margin: 0;
    height: 28px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 60px;
      padding: 0 6px;
    }
  }
  .ant-radio-wrapper-checked > span {
    background-color: #3b4554;
    color: #fff;
  }
}

// Duo select
.duo-select {
  display: flex !important;
  max-width: 120px;
  margin: 16px auto 0 !important;
  background-color: rgba(127, 190, 235, 0.2);
  font-size: 13px;
  font-weight: bold;
  line-height: 1.23;
  color: #7fbeeb;
  text-transform: uppercase;
  border-radius: 17px;
  overflow: hidden;
  &.duo-select--wide {
    max-width: 200px;
    text-transform: initial;
    .ant-radio-wrapper {
      font-size: 13px;
      span {
        width: 100px;
      }
    }
  }
  .ant-radio {
    display: none !important;
  }
  .ant-radio-wrapper {
    margin: 0;
    height: 28px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 60px;
      padding: 0 6px;
    }
  }
  .ant-radio-wrapper-checked > span {
    background-color: #3b4554;
    color: #fff;
  }
}

// Braintree
#braintreePayment {
  font-family: 'Boston', sans-serif !important;

  .braintree-heading {
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    color: #434e5f;
    width: 100%;
    font-size: 24px;
    letter-spacing: 1px;
    font-family: 'Boston', sans-serif !important;
  }

  .braintree-option {
    position: relative;
    align-items: center;
    background-color: #ffff;
    border: 1px solid #e4e6e7;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    padding: 16px 24px;
    margin-top: 16px;
    min-height: 64px;

    &:hover {
      &:after {
        transform: translateX(6px);
      }
    }
    &:after {
      content: '\2192';
      position: absolute;
      right: 16px;
      top: 0;
      bottom: 0;
      z-index: 1;
      margin: auto;
      display: inline-flex;
      align-items: center;
      font-size: 24px;
      transition: all 0.3s ease;
    }
  }
  .braintree-option__label {
    font-size: 24px;
    font-family: 'Boston', sans-serif;
    font-weight: 700;
    color: #434e5f;
  }
  .braintree-option__paypal {
    background-color: #ffc439;
    border: none;
  }
  .braintree-card .braintree-sheet__logo--header {
    display: none;
  }
  .braintree-paypal {
    .braintree-sheet__content--button {
      padding: 0;
    }
    .braintree-sheet__label {
      font-family: 'Boston', sans-serif;
      font-size: 22px;
      font-weight: 700;
      color: #434e5f;
    }
    .braintree-sheet__logo--header {
      margin-right: -12px;
    }
    .braintree-sheet__header-label {
      padding-bottom: 0 !important;
    }
  }
  .braintree-sheet__header {
    display: none !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    position: relative;
    .braintree-sheet__text {
      margin-left: 0;
      font-size: 24px;
      font-weight: 900;
    }
    .braintree-sheet__header-label {
      flex-grow: 0;
      padding-bottom: 12px;
    }
  }
  .braintree-placeholder {
    display: none;
  }
  .braintree-sheet {
    background-color: transparent;
    border: none;
  }
  .braintree-sheet__content--form {
    padding: 0;
  }
  .braintree-sheet__text {
    font-family: 'Boston', sans-serif;
    color: #434e5f;
    text-transform: uppercase;
    font-weight: 700;
  }
  .braintree-form__label {
    font-family: 'Boston', sans-serif;
    font-size: 12px;
    font-weight: 700;
    color: #434e5f;
    text-transform: uppercase;
  }
  .braintree-form__field-group {
    margin-top: 16px;
    flex-basis: 300px;
  }
  .braintree-sheet__content--form
    .braintree-form__field-group
    .braintree-form__field-error {
    font-family: 'Boston', sans-serif;
  }
  .braintree-sheet__content--form
    .braintree-form__field-group
    .braintree-form__field
    .braintree-form__hosted-field {
    border-radius: 8px;
    height: 56px;
    background-color: #fff;
  }
  .braintree-toggle {
    padding: 2px 4px;
    &:hover {
      font-weight: 400;
      background-color: transparent;
    }
    span {
      font-family: 'Boston', sans-serif;
      color: #434e5f;
      border-color: #434e5f;
    }
  }
}
.dropin-box {
  opacity: 0;
  height: 0;
}
// Blinker
.blink_me {
  animation: blinker 0.7s linear infinite;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
