.not-found.not-found {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: unset;
  max-height: unset;
  align-items: center;
  justify-content: center;
  .wrapper {
    display: flex;
    width: unset;
    height: unset;
    max-width: unset;
    max-height: unset;
    .header {
      text-align: left;
    }
  }
}

.default-page-wrapper {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .default-page-inner {
    max-width: 960px;
    margin: auto;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  &.homepage {
    .homepage__wrapper {
      max-width: 1120px;
      width: 100%;
      position: relative;
      padding: 40px 0;
      min-height: calc((var(--vh, 1vh) * 100 - 55px));
    }
    .homepage__bg {
      position: fixed;
      height: 100vh;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      img {
        height: 100%;
      }
    }
    .homepage__details {
      max-width: 50vw;
      z-index: 1;
      h1 {
        font-weight: 900;
        font-size: 40px;
        line-height: 50px;
        color: #4d5767;
        margin: 0;
        padding: 0;
      }
      .homepage__want {
        margin-top: 16px;
        h5 {
          font-weight: 800;
          font-size: 28px;
          line-height: 37px;
          color: #4d5767;
          margin: 0;
          padding: 0;
        }
        .homepage__want_buttons {
          margin-top: 32px;
          padding-top: 106px;
          position: relative;
          display: inline-flex;
          .arrows {
            width: 26px;
            height: 26px;
            border-left: 5px solid #96e3dd;
            border-bottom: 5px solid #96e3dd;
            position: absolute;
            left: calc(50% - 13px);
            top: 10px;
            transform: rotate(-45deg);
            &::after {
              content: '';
              display: block;
              width: 18px;
              height: 18px;
              border-left: 5px solid #96e3dd;
              border-bottom: 5px solid #96e3dd;
              position: absolute;
              left: 5px;
              top: -2px;
            }
          }
          .btn {
            width: 216px;
            height: 216px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #ffffff;
            box-shadow: 0px 10px 25px rgba(45, 75, 73, 0.1);
            border-radius: 10px;
            border: 1px solid #4d5767;
            cursor: pointer;
            transition: box-shadow 300ms ease;
            font-family: 'Boston', sans-serif;
            &:hover {
              box-shadow: none;
            }
            span {
              font-weight: 800;
              font-size: 20px;
              line-height: 24px;
              text-align: center;
              letter-spacing: -0.428571px;
              text-transform: uppercase;
              color: #4d5767;
              margin-top: 29px;
              white-space: pre-line;
            }
            &.cardiogram {
              margin-right: 32px;
              img {
                width: 56px;
                height: 51px;
              }
              span {
                width: 110px;
              }
            }
            &.waist {
              img {
                width: 43px;
                height: 56px;
              }
              span {
                width: 162px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .default-page-wrapper.homepage .homepage__wrapper {
    display: flex;
    justify-content: center;
    padding: 1vh 0 24px 0;
  }
  .default-page-wrapper.homepage .homepage__details {
    max-width: 90vw;
    flex-grow: 1;
  }
  .homepage__want {
    text-align: center;
  }
  .default-page-wrapper.homepage .homepage__bg {
    position: relative;
    height: calc(100vh - 80px);
  }

  .default-page-wrapper.homepage .homepage__details h1 {
    display: none;
  }
  .default-page-wrapper.homepage .homepage__details .homepage__want {
    width: 100%;
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    h5 {
      font-weight: 800;
      font-size: 6vh;
      line-height: 1.3em;
      text-transform: uppercase;
    }
    .homepage__want_buttons {
      padding-top: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0;
      width: 100%;
      .btn {
        max-width: 240px;
        height: auto;
        width: 100%;
        padding: 12px;
        margin: 12px 0 !important;
        img {
          width: 30% !important;
        }
        span {
          font-size: 16px;
          line-height: 20px;
          margin-top: 15px;
        }
      }
    }
  }
  .default-page-wrapper.homepage
    .homepage__details
    .homepage__want
    .homepage__want_buttons
    .btn.waist
    span {
    width: 130px;
  }
}

.homapage__footer {
  margin: 32px 0;
  font-size: 16px;
  color: #a1a1a1;
  .logo {
    width: 100%;
    max-width: 200px;
  }
  h3 {
    color: inherit;
    font-weight: 700;
  }
  a {
    color: #96e3dd;
  }
  .need-help-col {
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .default-page-wrapper.homepage .homepage__wrapper {
    display: flex;
    justify-content: flex-start;
    padding: 1vh 0 24px 0;
  }
  .default-page-wrapper.homepage .homepage__details {
    max-width: 50vw;
  }
  .default-page-wrapper.homepage .homepage__bg {
    position: absolute;
    height: calc(100vh - 80px);
    top: 0;
    right: auto;
    left: 50vw;
  }
  .homapage__footer {
    text-align: center;
  }
}

@keyframes scrollDown {
  0% {
    transform: translateY(-25px) rotate(-45deg);
    opacity: 0;
  }
  50% {
    transform: translateY(0px) rotate(-45deg);
    opacity: 1;
  }
  100% {
    transform: translateY(25px) rotate(-45deg);
    opacity: 0;
  }
}
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #eaf9f8;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  img {
    max-width: 200px;
    animation: blinker2 1s linear infinite;
  }
}

@keyframes blinker2 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
